<script lang="ts" setup>
declare global {
  interface Window {
    EviWidget: any
  }
}

interface Props {
  id: string
  __typename: 'CmsEventimNewsletterWidgetBlock'
  renderAboveTheFold?: boolean
}

defineProps<Props>()

const { getConsentForDomain } = useConsentManager()
const hasConsent = computed(() => getConsentForDomain('static.eventim.com'))
const containerId = useId()

const { load, unload } = useScriptTag(
  'https://static.eventim.com/evi/3.14/evi-widget.js',
  initWidget,
  { manual: true },
)

async function initWidget() {
  const { data } = await useFetch('https://cine.entradas.com/ajax/eviToken', {
    method: 'GET',
  })

  if (data.value) {
    new window.EviWidget(`#${containerId}`).create({
      baseURL: 'https://public-api.eventim.com/evi/api/evi',
      token: data.value,
    })
  }
}

onMounted(async () => {
  if (hasConsent.value) {
    await load()
  }
})

onUnmounted(async () => {
  await unload()
})

watch(hasConsent, async () => {
  if (hasConsent.value) {
    await load()
  } else {
    await unload()
  }
})

defineOptions({
  name: 'CmsBlockEventimNewsletter',
})
</script>

<template>
  <div data-eventim-newsletter-widget class="relative">
    <TransitionFade>
      <div
        v-if="hasConsent"
        :id="containerId"
        class="border-neutral bg-light relative rounded-md border p-6 shadow-lg empty:hidden sm:pl-[150px]"
      />
      <ConsentManagerWarning
        v-else
        :can-be-activated="true"
        :foreign-domains="['static.eventim.com', 'public-api.eventim.com']"
        class="h-76 w-full rounded-lg py-10"
      />
    </TransitionFade>
  </div>
</template>

<style>
.evi-widget-blacklisted-error,
.evi-widget-consent-error,
.evi-widget-hidden,
.evi-widget .evi-widget-title .evi-widget-last-modified,
.evi-widget .evi-widget-title-permitted .evi-widget-last-modified,
.evi-widget .evi-widget-title-success .evi-widget-last-modified,
.evi-widget-switch,
.evi-widget-type-general-newsletter.evi-widget-permitted {
  display: none;
}

.evi-widget-blacklisted-error,
.evi-widget-consent-error,
.evi-widget-form-error {
  margin: 4px 0 0;
  padding: 0;
  color: #e40000;
  font-size: 0.75rem;
  width: 100%;
}

.evi-widget-resend-permission-link,
.evi-widget-resend-permission-link-success {
  margin-bottom: 8px;
  padding-top: 8px;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #5c5c5c;
  width: 100%;
}

.evi-widget-doi-sent {
  display: block;
  background: 0 0;
}

.evi-widget-form-error {
  order: 1;
}

.evi-widget-success {
  min-height: 60px;
}

.evi-widget-type-general-newsletter::before {
  content: '';
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translate(-50%, 0);
  width: 80px;
  height: 80px;
}

.evi-widget {
  width: 100%;
}

.evi-widget button[type='submit'] {
  height: 42px;
  font-size: 0.875rem;
  border-radius: 3px;
  padding: 4px 12px;
  line-height: 1.25rem;
  color: #fff;
  background-color: #ffb201;
  transition: all 0.1s linear;
  display: inline-block;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  overflow-wrap: break-word;
  text-decoration: none;
  user-select: none;
  width: 100%;
  margin-top: 12px;
  order: 2;
}

.evi-widget input[name='email'] {
  height: 42px;
  font-size: 1rem;
  line-height: 1.625rem;
  border: 1px solid #ccc;
  padding: 4px 8px;
  margin-right: 0;
  border-radius: 3px;
  color: #07446f;
  box-shadow: none;
  width: 100%;
  order: 0;
  background-color: #fff;
}

.evi-widget input[name='email']:focus {
  box-shadow:
    0 1px 1px 0 rgba(60, 64, 67, 0.1),
    0 1px 3px 1px rgba(60, 64, 67, 0.2);
  transition: box-shadow linear 0.2s;
}

.evi-widget ::-webkit-input-placeholder {
  color: #07446f;
}

.evi-widget ::-moz-placeholder {
  color: #07446f;
}

.evi-widget :-ms-input-placeholder,
.evi-widget ::-ms-input-placeholder {
  color: #07446f;
}

.evi-widget ::placeholder {
  color: #07446f;
}

.evi-widget .evi-widget-description,
.evi-widget .evi-widget-description-doi-sent,
.evi-widget .evi-widget-description-success,
.evi-widget .evi-widget-title-doi-sent {
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: center;
  color: #5c5c5c;
  width: 100%;
}

.evi-widget .evi-widget-description {
  margin-bottom: 12px;
}

.evi-widget .evi-widget-description-success {
  margin-bottom: 0;
}

.evi-widget input:-ms-input-placeholder {
  color: #5c5c5c;
}

.evi-widget input:-moz-placeholder {
  color: #5c5c5c;
}

input[name='email'].evi-widget-input-error {
  border-color: #e40000;
}

.evi-widget-type-general-newsletter,
.evi-widget-type-general-newsletter .evi-widget-subscription form > div {
  display: flex;
  flex-wrap: wrap;
}

.evi-widget-type-general-newsletter {
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
}

.evi-widget-type-general-newsletter::before {
  background-image: url("data:image/svg+xml, %3Csvg clip-rule='evenodd' fill-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='1.414' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg' fill='rgb%28255%2C178%2C1%29'%3E%3Cg fill-rule='nonzero'%3E%3Cpath d='M21.764 22.573c-.49-.526-1.054-.873-1.6-1.21-.962-.593-1.72-1.061-1.72-2.296 0-1.053.76-1.91 1.695-1.91s1.695.857 1.695 1.91c0 .255.461.255.461 0 0-1.053.761-1.91 1.696-1.91.934 0 1.695.857 1.695 1.91 0 1.235-.759 1.703-1.722 2.297-.518.318-1.106.682-1.599 1.21l-.3.32zm2.441-.815c1.001-.618 1.943-1.2 1.943-2.691 0-1.307-.968-2.371-2.157-2.371-.61 0-1.197.289-1.609.793l-.317.39-.319-.39c-.41-.504-.997-.793-1.607-.793-1.19 0-2.157 1.064-2.157 2.37 0 1.493.94 2.075 1.936 2.69.678.418 1.444.888 1.95 1.714.083.135.31.136.392-.001.507-.825 1.272-1.295 1.945-1.712'/%3E%3Cpath d='M22.065 26.968c-4.121 0-7.473-3.353-7.473-7.474 0-4.12 3.352-7.473 7.473-7.473s7.474 3.353 7.474 7.473c0 4.121-3.353 7.474-7.474 7.474m-7.934-7.704H.834l8.553-7.801 2.891 2.637c.075.066.179.121.312 0l2.896-2.642 1.824 1.674-.355.301a7.883 7.883 0 00-2.795 5.45zM.467 3.325l8.578 7.825-8.578 7.821zm23.935 0v8.577l-.506-.121a7.765 7.765 0 00-5.925.93l-.263.159-1.878-1.724zm-.365-.292L12.435 13.617.832 3.033zm1.08 9.138l-.253-.106-.094-9.439-.147-.055-24.502.031-.085.126L0 2.857l.059 16.762.076.104.007-.017 13.989.019.029.381c.316 4.106 3.788 7.323 7.905 7.323 4.376 0 7.935-3.559 7.935-7.935a7.923 7.923 0 00-4.883-7.323'/%3E%3C/g%3E%3C/svg%3E");
  background-size: contain;
}

.evi-widget-type-general-newsletter.evi-widget-success::before {
  background-image: url("data:image/svg+xml, %3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='1.414' fill='rgb%28255%2C178%2C1%29'%3E%3Cpath d='M25.958 16.502l-4.728 5.17-2.847-2.215a.313.313 0 00-.432.047.293.293 0 00.048.42l3.075 2.392a.315.315 0 00.423-.035l4.921-5.383a.293.293 0 00-.025-.421.314.314 0 00-.435.025' fill-rule='nonzero'/%3E%3Cpath d='M22.065 26.968c-4.121 0-7.473-3.353-7.473-7.474 0-4.12 3.352-7.473 7.473-7.473s7.474 3.353 7.474 7.473c0 4.121-3.353 7.474-7.474 7.474m-7.934-7.704H.834l8.553-7.801 2.891 2.637c.075.066.179.121.312 0l2.896-2.642 1.824 1.674-.355.301a7.883 7.883 0 00-2.795 5.45l-.029.381zM.467 3.325l8.578 7.825-8.578 7.821V3.325zm23.935 0v8.577l-.506-.121a7.765 7.765 0 00-5.925.93l-.263.159-1.878-1.724 8.572-7.821zm-.365-.292L12.435 13.617.832 3.033h23.205zm1.08 9.138l-.253-.106-.094-9.439-.147-.055-24.502.031-.085.126L0 2.857l.059 16.762.076.104.007-.017 13.989.019.029.381c.316 4.106 3.788 7.323 7.905 7.323 4.376 0 7.935-3.559 7.935-7.935a7.923 7.923 0 00-4.883-7.323' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-size: contain;
}

.evi-widget-type-general-newsletter .evi-widget-subscription {
  width: 100%;
  padding-left: 0;
  margin-bottom: 8px;
}

.evi-widget-type-general-newsletter .evi-widget-subscription label {
  color: #07446f;
  font-weight: 500;
  font-size: 0.875rem;
  margin-top: 20px;
  display: none;
}

.evi-widget-type-general-newsletter .evi-widget-subscription form > div {
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.evi-widget-type-general-newsletter .evi-widget-blacklisted-error {
  margin-bottom: 4px;
}

.evi-widget-type-general-newsletter .evi-widget-footer {
  font-size: 0.75rem;
  color: #5c5c5c;
  margin-top: 8px;
}

.evi-widget .evi-widget-consent {
  display: block;
  padding-left: 0;
  width: 100%;
}

.evi-widget-consent-error,
.evi-widget.evi-consent-check .evi-widget-consent {
  padding-left: 24px;
}

.evi-consent-check .evi-widget-consent .evi-widget-consent-label {
  color: #5c5c5c;
}

.evi-consent-check
  .evi-widget-consent
  input[type='checkbox'].evi-widget-consent-required {
  display: block;
  opacity: 0;
}

.evi-widget-checkbox {
  visibility: hidden;
  position: absolute;
}

.evi-widget-type-checkbox .evi-widget-consent {
  padding-left: 40px;
}

.evi-widget-checkbox + .evi-widget-label,
.evi-widget-consent + .evi-widget-consent-label {
  display: inline-block;
  max-width: 100%;
}

.evi-widget-consent-label,
.evi-widget-label {
  position: relative;
  margin-bottom: 0;
  user-select: none;
  line-height: 1.5rem;
  color: #07446f;
  min-height: 28px;
  cursor: pointer;
  font-size: 1rem;
}

.evi-widget-label {
  padding: 0 0 4px 40px;
}

.evi-widget-consent-label {
  padding: 0;
}

.evi-consent-check .evi-widget-consent-label::after,
.evi-consent-check .evi-widget-consent-label::before,
.evi-widget-label::after,
.evi-widget-label::before {
  content: '';
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  top: -2px;
  left: -24px;
}

.evi-widget-label::after,
.evi-widget-label::before {
  left: 8px;
  top: 24px;
}

.evi-consent-check .evi-widget-consent-label::before,
.evi-widget-label::before {
  transform: scale(0.01);
  opacity: 0;
  transition:
    transform 0.15s,
    opacity 0.15s;
  transition:
    transform 0.15s,
    opacity 0.15s,
    -webkit-transform 0.15s;
  transition-timing-function: ease-in-out;
}

.evi-consent-check .evi-widget-consent-label::after,
.evi-widget-label::after {
  border: 1px solid #aebcd2;
  background-image: url("data:image/svg+xml, %3Csvg viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' fill='rgb%287%2C176%2C60%29'%3E%3Cpath d='M200 397.8L66.43 269.435l37.4-35.943L200 325.915 403.03 130.8l37.4 35.942L200 397.8z' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: 0 center;
  background-clip: content-box;
  background-size: 19px 19px;
  padding-right: 17px;
  transition: padding-right 0.2s;
  transition-delay: 0.15s;
  transition-timing-function: ease-out;
}

.evi-consent-check
  input.evi-widget-consent-required.evi-widget-input-error
  + .evi-widget-consent-label::after,
.evi-consent-check
  input.evi-widget-consent-required.evi-widget-input-error
  + .evi-widget-consent-label::before {
  border: 1px solid #e40000;
}

.evi-widget-consent,
.evi-widget-consent .evi-widget-consent-label {
  font-size: 0.625rem;
  line-height: 1rem;
  margin-top: 0;
  margin-bottom: 0;
  color: #5c5c5c;
  width: auto;
}

.address-form .evi-widget-consent {
  padding: 0 0 8px 32px;
  color: inherit;
}

.evi-widget-consent a {
  display: inline;
  color: #139df4;
  text-decoration: underline !important;
}

.evi-widget-checkbox:checked + label::before,
.evi-widget-consent-required:checked + label::before {
  opacity: 1;
  transform: scale(1);
}

.evi-widget-checkbox:checked + label::after,
.evi-widget-consent-required:checked + label::after {
  padding-right: 0;
  animation: input-blip 0.15s ease-in-out;
  animation-delay: 0.1s;
}

.eevi-widget-consent-required:disabled + label::before,
.evi-widget-checkbox:disabled + label::before {
  background-color: #e5ebf5;
  opacity: 1;
  transform: scale(1);
}

.evi-widget-checkbox:disabled + label::after,
.evi-widget-consent-required:disabled + label::after {
  background-image: url("data:image/svg+xml, %3Csvg viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' fill='rgb%28174%2C188%2C210%29'%3E%3Cpath d='M200 397.8L66.43 269.435l37.4-35.943L200 325.915 403.03 130.8l37.4 35.942L200 397.8z' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: 0 center;
  background-clip: content-box;
  background-size: 19px 19px;
  cursor: default;
  border: 1px solid #aebcd2;
}

.evi-widget.evi-widget-type-category-newsletter-cockpit,
.evi-widget.evi-widget-type-general-newsletter-cockpit {
  background: #fff;
}

.evi-widget.evi-widget-type-category-newsletter-cockpit .evi-widget-footer,
.evi-widget.evi-widget-type-general-newsletter-cockpit .evi-widget-footer {
  color: #5c5c5c;
  line-height: 1.25rem;
}

.evi-widget.evi-widget-type-category-newsletter-cockpit
  .evi-widget-consent-label,
.evi-widget.evi-widget-type-general-newsletter-cockpit
  .evi-widget-consent-label {
  line-height: 1.25rem;
  font-weight: 400;
}

.evi-widget.evi-widget-type-category-newsletter-cockpit
  .evi-widget-resend-permission-link,
.evi-widget.evi-widget-type-general-newsletter-cockpit
  .evi-widget-resend-permission-link {
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.newsletter-cockpit .evi-widget .evi-widget-description,
.newsletter-cockpit .evi-widget .evi-widget-description-doi-sent,
.newsletter-cockpit .evi-widget .evi-widget-description-success {
  font-size: 0.75rem;
  line-height: 1.25rem;
  text-align: left;
}

.evi-widget-type-category-newsletter-cockpit .evi-widget-title {
  text-align: left;
}

.evi-widget.evi-widget-type-general-newsletter-cockpit .evi-widget-footer {
  border: 0;
}

.evi-widget.evi-widget-type-general-newsletter-cockpit .evi-widget-consent {
  padding-top: 8px;
}

.evi-widget.evi-widget-type-general-newsletter-cockpit .evi-widget-switch-list {
  border-bottom: 1px solid #ccc;
}

.evi-widget.evi-widget-type-general-newsletter-cockpit
  .evi-widget-switch-list
  li {
  margin-bottom: 0;
}

.evi-widget-type-radio:not(:last-child),
.evi-widget-type-radio > div:not(:last-child) {
  padding-bottom: 12px;
}

.evi-widget-type-radio > input[type='radio'] + label {
  padding: 4px 16px 4px 36px;
  display: inline-block;
  position: relative;
  line-height: 1.5rem;
  min-height: 32px;
  font-size: 0.75rem;
}

.evi-widget-type-radio > input[type='radio'] + label::after,
.evi-widget-type-radio > input[type='radio'] + label::before {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #ccc;
  transition: all 250ms ease;
}

.evi-widget-type-radio > input[type='radio'] + label::after {
  padding-right: 0;
  background-color: #ccc;
  box-shadow: inset 0 0 0 7px #fff;
  background-image: none;
}

.evi-widget-type-radio > input[type='radio'] + label:hover::after {
  box-shadow: inset 0 0 0 6px #fff;
}

.evi-widget-type-radio > input[type='radio']:checked + label::after {
  padding-right: 0;
  background-color: #07b03c;
  box-shadow: inset 0 0 0 5px #fff;
  border-color: #07b03c;
  animation: input-blip 0.15s ease-in-out;
  animation-delay: 0.1s;
}

.evi-widget-type-radio > input[type='radio']:disabled + label {
  pointer-events: none;
  cursor: default;
}

@media (min-width: 660px) {
  .evi-widget-type-radio > input[type='radio'] + label {
    font-size: 0.875rem;
  }

  .evi-consent-check .evi-widget-consent-label::after,
  .evi-consent-check .evi-widget-consent-label::before {
    left: -28px;
  }

  .evi-widget-consent,
  .evi-widget-consent .evi-widget-consent-label {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  .evi-widget-resend-permission-link,
  .evi-widget-resend-permission-link-success {
    margin-bottom: 12px;
  }

  .evi-widget-type-general-newsletter::before {
    left: 24px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .evi-widget button[type='submit'] {
    width: 47%;
    margin-top: 0;
    order: 1;
  }

  .evi-widget input[name='email'] {
    width: 47%;
  }

  .evi-widget .evi-widget-title,
  .evi-widget .evi-widget-title-permitted,
  .evi-widget .evi-widget-title-success {
    font-size: 1.875rem;
    margin-bottom: 12px;
  }

  .evi-widget .evi-widget-description,
  .evi-widget .evi-widget-description-doi-sent,
  .evi-widget .evi-widget-description-success,
  .evi-widget .evi-widget-title-doi-sent {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .evi-widget-type-general-newsletter {
    padding-top: 0;
  }

  .evi-widget-type-general-newsletter .evi-widget-subscription {
    width: 70%;
  }
}

@media (min-width: 950px) {
  .evi-widget-type-general-newsletter .evi-widget-subscription {
    width: 65%;
  }
}
</style>
